import { unit } from '@ant-design/cssinjs';
import { genStyleHooks } from '../../theme/internal';
const genLayoutStyle = (token) => {
    const { antCls, // .ant
    componentCls, // .ant-layout
    colorText, footerBg, headerHeight, headerPadding, headerColor, footerPadding, fontSize, bodyBg, headerBg, } = token;
    return {
        [componentCls]: {
            display: 'flex',
            flex: 'auto',
            flexDirection: 'column',
            /* fix firefox can't set height smaller than content on flex item */
            minHeight: 0,
            background: bodyBg,
            '&, *': {
                boxSizing: 'border-box',
            },
            [`&${componentCls}-has-sider`]: {
                flexDirection: 'row',
                [`> ${componentCls}, > ${componentCls}-content`]: {
                    // https://segmentfault.com/a/1190000019498300
                    width: 0,
                },
            },
            [`${componentCls}-header, &${componentCls}-footer`]: {
                flex: '0 0 auto',
            },
            // RTL
            '&-rtl': {
                direction: 'rtl',
            },
        },
        // ==================== Header ====================
        [`${componentCls}-header`]: {
            height: headerHeight,
            padding: headerPadding,
            color: headerColor,
            lineHeight: unit(headerHeight),
            background: headerBg,
            // Other components/menu/style/index.less line:686
            // Integration with header element so menu items have the same height
            [`${antCls}-menu`]: {
                lineHeight: 'inherit',
            },
        },
        // ==================== Footer ====================
        [`${componentCls}-footer`]: {
            padding: footerPadding,
            color: colorText,
            fontSize,
            background: footerBg,
        },
        // =================== Content ====================
        [`${componentCls}-content`]: {
            flex: 'auto',
            color: colorText,
            // fix firefox can't set height smaller than content on flex item
            minHeight: 0,
        },
    };
};
export const prepareComponentToken = (token) => {
    const { colorBgLayout, controlHeight, controlHeightLG, colorText, controlHeightSM, marginXXS, colorTextLightSolid, colorBgContainer, } = token;
    const paddingInline = controlHeightLG * 1.25;
    return {
        // Deprecated
        colorBgHeader: '#001529',
        colorBgBody: colorBgLayout,
        colorBgTrigger: '#002140',
        bodyBg: colorBgLayout,
        headerBg: '#001529',
        headerHeight: controlHeight * 2,
        headerPadding: `0 ${paddingInline}px`,
        headerColor: colorText,
        footerPadding: `${controlHeightSM}px ${paddingInline}px`,
        footerBg: colorBgLayout,
        siderBg: '#001529',
        triggerHeight: controlHeightLG + marginXXS * 2,
        triggerBg: '#002140',
        triggerColor: colorTextLightSolid,
        zeroTriggerWidth: controlHeightLG,
        zeroTriggerHeight: controlHeightLG,
        lightSiderBg: colorBgContainer,
        lightTriggerBg: colorBgContainer,
        lightTriggerColor: colorText,
    };
};
// ============================== Export ==============================
export const DEPRECATED_TOKENS = [
    ['colorBgBody', 'bodyBg'],
    ['colorBgHeader', 'headerBg'],
    ['colorBgTrigger', 'triggerBg'],
];
export default genStyleHooks('Layout', (token) => [genLayoutStyle(token)], prepareComponentToken, {
    deprecatedTokens: DEPRECATED_TOKENS,
});
