import * as React from 'react';
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import RightOutlined from '@ant-design/icons/RightOutlined';
import Button from '../button';
const Operation = (props) => {
    const { disabled, moveToLeft, moveToRight, leftArrowText = '', rightArrowText = '', leftActive, rightActive, className, style, direction, oneWay, } = props;
    return (React.createElement("div", { className: className, style: style },
        React.createElement(Button, { type: "primary", size: "small", disabled: disabled || !rightActive, onClick: moveToRight, icon: direction !== 'rtl' ? React.createElement(RightOutlined, null) : React.createElement(LeftOutlined, null) }, rightArrowText),
        !oneWay && (React.createElement(Button, { type: "primary", size: "small", disabled: disabled || !leftActive, onClick: moveToLeft, icon: direction !== 'rtl' ? React.createElement(LeftOutlined, null) : React.createElement(RightOutlined, null) }, leftArrowText))));
};
if (process.env.NODE_ENV !== 'production') {
    Operation.displayName = 'Operation';
}
export default Operation;
