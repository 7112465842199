import * as React from 'react';
import { groupKeysMap } from '../../_util/transKeys';
const useData = (dataSource, rowKey, targetKeys) => {
    const mergedDataSource = React.useMemo(() => (dataSource || []).map((record) => {
        if (rowKey) {
            return Object.assign(Object.assign({}, record), { key: rowKey(record) });
        }
        return record;
    }), [dataSource, rowKey]);
    const [leftDataSource, rightDataSource] = React.useMemo(() => {
        const leftData = [];
        const rightData = new Array((targetKeys || []).length);
        const targetKeysMap = groupKeysMap(targetKeys || []);
        mergedDataSource.forEach((record) => {
            // rightData should be ordered by targetKeys
            // leftData should be ordered by dataSource
            if (targetKeysMap.has(record.key)) {
                rightData[targetKeysMap.get(record.key)] = record;
            }
            else {
                leftData.push(record);
            }
        });
        return [leftData, rightData];
    }, [mergedDataSource, targetKeys, rowKey]);
    return [mergedDataSource, leftDataSource, rightDataSource];
};
export default useData;
