import * as React from 'react';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import Input from '../../../input';
const FilterSearch = (props) => {
    const { value, filterSearch, tablePrefixCls, locale, onChange } = props;
    if (!filterSearch) {
        return null;
    }
    return (React.createElement("div", { className: `${tablePrefixCls}-filter-dropdown-search` },
        React.createElement(Input, { prefix: React.createElement(SearchOutlined, null), placeholder: locale.filterSearchPlaceholder, onChange: onChange, value: value, 
            // for skip min-width of input
            htmlSize: 1, className: `${tablePrefixCls}-filter-dropdown-search-input` })));
};
export default FilterSearch;
