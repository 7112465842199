import * as React from 'react';
import classNames from 'classnames';
import Progress from './Progress';
export default function Looper(props) {
    const { prefixCls, percent = 0 } = props;
    const dotClassName = `${prefixCls}-dot`;
    const holderClassName = `${dotClassName}-holder`;
    const hideClassName = `${holderClassName}-hidden`;
    // ===================== Render =====================
    return (React.createElement(React.Fragment, null,
        React.createElement("span", { className: classNames(holderClassName, percent > 0 && hideClassName) },
            React.createElement("span", { className: classNames(dotClassName, `${prefixCls}-dot-spin`) }, [1, 2, 3, 4].map((i) => (React.createElement("i", { className: `${prefixCls}-dot-item`, key: i }))))),
        React.createElement(Progress, { prefixCls: prefixCls, percent: percent })));
}
