import React from 'react';
import HolderOutlined from '@ant-design/icons/HolderOutlined';
import classNames from 'classnames';
import RcTree from 'rc-tree';
import initCollapseMotion from '../_util/motion';
import { ConfigContext } from '../config-provider';
import { useToken } from '../theme/internal';
import useStyle from './style';
import dropIndicatorRender from './utils/dropIndicator';
import SwitcherIconCom from './utils/iconUtil';
const Tree = React.forwardRef((props, ref) => {
    var _a;
    const { getPrefixCls, direction, virtual, tree } = React.useContext(ConfigContext);
    const { prefixCls: customizePrefixCls, className, showIcon = false, showLine, switcherIcon, switcherLoadingIcon, blockNode = false, children, checkable = false, selectable = true, draggable, motion: customMotion, style, } = props;
    const prefixCls = getPrefixCls('tree', customizePrefixCls);
    const rootPrefixCls = getPrefixCls();
    const motion = customMotion !== null && customMotion !== void 0 ? customMotion : Object.assign(Object.assign({}, initCollapseMotion(rootPrefixCls)), { motionAppear: false });
    const newProps = Object.assign(Object.assign({}, props), { checkable,
        selectable,
        showIcon,
        motion,
        blockNode, showLine: Boolean(showLine), dropIndicatorRender });
    const [wrapCSSVar, hashId, cssVarCls] = useStyle(prefixCls);
    const [, token] = useToken();
    const itemHeight = token.paddingXS / 2 + (((_a = token.Tree) === null || _a === void 0 ? void 0 : _a.titleHeight) || token.controlHeightSM);
    const draggableConfig = React.useMemo(() => {
        if (!draggable) {
            return false;
        }
        let mergedDraggable = {};
        switch (typeof draggable) {
            case 'function':
                mergedDraggable.nodeDraggable = draggable;
                break;
            case 'object':
                mergedDraggable = Object.assign({}, draggable);
                break;
            default:
                break;
            // Do nothing
        }
        if (mergedDraggable.icon !== false) {
            mergedDraggable.icon = mergedDraggable.icon || React.createElement(HolderOutlined, null);
        }
        return mergedDraggable;
    }, [draggable]);
    const renderSwitcherIcon = (nodeProps) => (React.createElement(SwitcherIconCom, { prefixCls: prefixCls, switcherIcon: switcherIcon, switcherLoadingIcon: switcherLoadingIcon, treeNodeProps: nodeProps, showLine: showLine }));
    return wrapCSSVar(
    // @ts-ignore
    React.createElement(RcTree, Object.assign({ itemHeight: itemHeight, ref: ref, virtual: virtual }, newProps, { 
        // newProps may contain style so declare style below it
        style: Object.assign(Object.assign({}, tree === null || tree === void 0 ? void 0 : tree.style), style), prefixCls: prefixCls, className: classNames({
            [`${prefixCls}-icon-hide`]: !showIcon,
            [`${prefixCls}-block-node`]: blockNode,
            [`${prefixCls}-unselectable`]: !selectable,
            [`${prefixCls}-rtl`]: direction === 'rtl',
        }, tree === null || tree === void 0 ? void 0 : tree.className, className, hashId, cssVarCls), direction: direction, checkable: checkable ? React.createElement("span", { className: `${prefixCls}-checkbox-inner` }) : checkable, selectable: selectable, switcherIcon: renderSwitcherIcon, draggable: draggableConfig }), children));
});
if (process.env.NODE_ENV !== 'production') {
    Tree.displayName = 'Tree';
}
export default Tree;
