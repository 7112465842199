import { unit } from '@ant-design/cssinjs';
import { genFocusStyle, resetComponent } from '../../style';
import { genStyleHooks, mergeToken } from '../../theme/internal';
const genBreadcrumbStyle = (token) => {
    const { componentCls, iconCls, calc } = token;
    return {
        [componentCls]: Object.assign(Object.assign({}, resetComponent(token)), { color: token.itemColor, fontSize: token.fontSize, [iconCls]: {
                fontSize: token.iconFontSize,
            }, ol: {
                display: 'flex',
                flexWrap: 'wrap',
                margin: 0,
                padding: 0,
                listStyle: 'none',
            }, a: Object.assign({ color: token.linkColor, transition: `color ${token.motionDurationMid}`, padding: `0 ${unit(token.paddingXXS)}`, borderRadius: token.borderRadiusSM, height: token.fontHeight, display: 'inline-block', marginInline: calc(token.marginXXS).mul(-1).equal(), '&:hover': {
                    color: token.linkHoverColor,
                    backgroundColor: token.colorBgTextHover,
                } }, genFocusStyle(token)), 'li:last-child': {
                color: token.lastItemColor,
            }, [`${componentCls}-separator`]: {
                marginInline: token.separatorMargin,
                color: token.separatorColor,
            }, [`${componentCls}-link`]: {
                [`
          > ${iconCls} + span,
          > ${iconCls} + a
        `]: {
                    marginInlineStart: token.marginXXS,
                },
            }, [`${componentCls}-overlay-link`]: {
                borderRadius: token.borderRadiusSM,
                height: token.fontHeight,
                display: 'inline-block',
                padding: `0 ${unit(token.paddingXXS)}`,
                marginInline: calc(token.marginXXS).mul(-1).equal(),
                [`> ${iconCls}`]: {
                    marginInlineStart: token.marginXXS,
                    fontSize: token.fontSizeIcon,
                },
                '&:hover': {
                    color: token.linkHoverColor,
                    backgroundColor: token.colorBgTextHover,
                    a: {
                        color: token.linkHoverColor,
                    },
                },
                a: {
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                },
            }, 
            // rtl style
            [`&${token.componentCls}-rtl`]: {
                direction: 'rtl',
            } }),
    };
};
export const prepareComponentToken = (token) => ({
    itemColor: token.colorTextDescription,
    lastItemColor: token.colorText,
    iconFontSize: token.fontSize,
    linkColor: token.colorTextDescription,
    linkHoverColor: token.colorText,
    separatorColor: token.colorTextDescription,
    separatorMargin: token.marginXS,
});
// ============================== Export ==============================
export default genStyleHooks('Breadcrumb', (token) => {
    const breadcrumbToken = mergeToken(token, {});
    return genBreadcrumbStyle(breadcrumbToken);
}, prepareComponentToken);
