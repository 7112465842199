import { unit } from '@ant-design/cssinjs';
/**
 * @private Internal usage only
 */
export const getTransBg = (size, colorFill) => ({
    backgroundImage: `conic-gradient(${colorFill} 0 25%, transparent 0 50%, ${colorFill} 0 75%, transparent 0)`,
    backgroundSize: `${size} ${size}`,
});
const genColorBlockStyle = (token, size) => {
    const { componentCls, borderRadiusSM, colorPickerInsetShadow, lineWidth, colorFillSecondary } = token;
    return {
        [`${componentCls}-color-block`]: Object.assign(Object.assign({ position: 'relative', borderRadius: borderRadiusSM, width: size, height: size, boxShadow: colorPickerInsetShadow, flex: 'none' }, getTransBg('50%', token.colorFillSecondary)), { [`${componentCls}-color-block-inner`]: {
                width: '100%',
                height: '100%',
                boxShadow: `inset 0 0 0 ${unit(lineWidth)} ${colorFillSecondary}`,
                borderRadius: 'inherit',
            } }),
    };
};
export default genColorBlockStyle;
