var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import { Panel } from 'rc-dialog';
import { withPureRenderTheme } from '../_util/PurePanel';
import { ConfigContext } from '../config-provider';
import useCSSVarCls from '../config-provider/hooks/useCSSVarCls';
import { ConfirmContent } from './ConfirmDialog';
import { Footer, renderCloseIcon } from './shared';
import useStyle from './style';
const PurePanel = (props) => {
    const { prefixCls: customizePrefixCls, className, closeIcon, closable, type, title, children, footer } = props, restProps = __rest(props, ["prefixCls", "className", "closeIcon", "closable", "type", "title", "children", "footer"]);
    const { getPrefixCls } = React.useContext(ConfigContext);
    const rootPrefixCls = getPrefixCls();
    const prefixCls = customizePrefixCls || getPrefixCls('modal');
    const rootCls = useCSSVarCls(rootPrefixCls);
    const [wrapCSSVar, hashId, cssVarCls] = useStyle(prefixCls, rootCls);
    const confirmPrefixCls = `${prefixCls}-confirm`;
    // Choose target props by confirm mark
    let additionalProps = {};
    if (type) {
        additionalProps = {
            closable: closable !== null && closable !== void 0 ? closable : false,
            title: '',
            footer: '',
            children: (React.createElement(ConfirmContent, Object.assign({}, props, { prefixCls: prefixCls, confirmPrefixCls: confirmPrefixCls, rootPrefixCls: rootPrefixCls, content: children }))),
        };
    }
    else {
        additionalProps = {
            closable: closable !== null && closable !== void 0 ? closable : true,
            title,
            footer: footer !== null && React.createElement(Footer, Object.assign({}, props)),
            children,
        };
    }
    return wrapCSSVar(React.createElement(Panel, Object.assign({ prefixCls: prefixCls, className: classNames(hashId, `${prefixCls}-pure-panel`, type && confirmPrefixCls, type && `${confirmPrefixCls}-${type}`, className, cssVarCls, rootCls) }, restProps, { closeIcon: renderCloseIcon(prefixCls, closeIcon), closable: closable }, additionalProps)));
};
export default withPureRenderTheme(PurePanel);
