var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { ConfigContext } from '../../config-provider';
import defaultLocale from '../../locale/en_US';
import useLocale from '../../locale/useLocale';
import ConfirmDialog from '../ConfirmDialog';
const HookModal = (_a, ref) => {
    var _b;
    var { afterClose: hookAfterClose, config } = _a, restProps = __rest(_a, ["afterClose", "config"]);
    const [open, setOpen] = React.useState(true);
    const [innerConfig, setInnerConfig] = React.useState(config);
    const { direction, getPrefixCls } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('modal');
    const rootPrefixCls = getPrefixCls();
    const afterClose = () => {
        var _a;
        hookAfterClose();
        (_a = innerConfig.afterClose) === null || _a === void 0 ? void 0 : _a.call(innerConfig);
    };
    const close = (...args) => {
        var _a;
        setOpen(false);
        const triggerCancel = args.some((param) => param === null || param === void 0 ? void 0 : param.triggerCancel);
        if (triggerCancel) {
            (_a = innerConfig.onCancel) === null || _a === void 0 ? void 0 : _a.call(innerConfig, () => { }, ...args.slice(1));
        }
    };
    React.useImperativeHandle(ref, () => ({
        destroy: close,
        update: (newConfig) => {
            setInnerConfig((originConfig) => (Object.assign(Object.assign({}, originConfig), newConfig)));
        },
    }));
    const mergedOkCancel = (_b = innerConfig.okCancel) !== null && _b !== void 0 ? _b : innerConfig.type === 'confirm';
    const [contextLocale] = useLocale('Modal', defaultLocale.Modal);
    return (React.createElement(ConfirmDialog, Object.assign({ prefixCls: prefixCls, rootPrefixCls: rootPrefixCls }, innerConfig, { close: close, open: open, afterClose: afterClose, okText: innerConfig.okText || (mergedOkCancel ? contextLocale === null || contextLocale === void 0 ? void 0 : contextLocale.okText : contextLocale === null || contextLocale === void 0 ? void 0 : contextLocale.justOkText), direction: innerConfig.direction || direction, cancelText: innerConfig.cancelText || (contextLocale === null || contextLocale === void 0 ? void 0 : contextLocale.cancelText) }, restProps)));
};
export default React.forwardRef(HookModal);
