import { unit } from '@ant-design/cssinjs';
import { TinyColor } from '@ctrl/tinycolor';
import { resetComponent } from '../../style';
import { genStyleHooks, mergeToken } from '../../theme/internal';
// ============================== Styles ==============================
const genBaseStyle = (token) => {
    const { paddingXXS, lineWidth, tagPaddingHorizontal, componentCls, calc } = token;
    const paddingInline = calc(tagPaddingHorizontal).sub(lineWidth).equal();
    const iconMarginInline = calc(paddingXXS).sub(lineWidth).equal();
    return {
        // Result
        [componentCls]: Object.assign(Object.assign({}, resetComponent(token)), { display: 'inline-block', height: 'auto', 
            // https://github.com/ant-design/ant-design/pull/47504
            marginInlineEnd: token.marginXS, paddingInline, fontSize: token.tagFontSize, lineHeight: token.tagLineHeight, whiteSpace: 'nowrap', background: token.defaultBg, border: `${unit(token.lineWidth)} ${token.lineType} ${token.colorBorder}`, borderRadius: token.borderRadiusSM, opacity: 1, transition: `all ${token.motionDurationMid}`, textAlign: 'start', position: 'relative', 
            // RTL
            [`&${componentCls}-rtl`]: {
                direction: 'rtl',
            }, '&, a, a:hover': {
                color: token.defaultColor,
            }, [`${componentCls}-close-icon`]: {
                marginInlineStart: iconMarginInline,
                fontSize: token.tagIconSize,
                color: token.colorTextDescription,
                cursor: 'pointer',
                transition: `all ${token.motionDurationMid}`,
                '&:hover': {
                    color: token.colorTextHeading,
                },
            }, [`&${componentCls}-has-color`]: {
                borderColor: 'transparent',
                [`&, a, a:hover, ${token.iconCls}-close, ${token.iconCls}-close:hover`]: {
                    color: token.colorTextLightSolid,
                },
            }, '&-checkable': {
                backgroundColor: 'transparent',
                borderColor: 'transparent',
                cursor: 'pointer',
                [`&:not(${componentCls}-checkable-checked):hover`]: {
                    color: token.colorPrimary,
                    backgroundColor: token.colorFillSecondary,
                },
                '&:active, &-checked': {
                    color: token.colorTextLightSolid,
                },
                '&-checked': {
                    backgroundColor: token.colorPrimary,
                    '&:hover': {
                        backgroundColor: token.colorPrimaryHover,
                    },
                },
                '&:active': {
                    backgroundColor: token.colorPrimaryActive,
                },
            }, '&-hidden': {
                display: 'none',
            }, 
            // To ensure that a space will be placed between character and `Icon`.
            [`> ${token.iconCls} + span, > span + ${token.iconCls}`]: {
                marginInlineStart: paddingInline,
            } }),
        [`${componentCls}-borderless`]: {
            borderColor: 'transparent',
            background: token.tagBorderlessBg,
        },
    };
};
// ============================== Export ==============================
export const prepareToken = (token) => {
    const { lineWidth, fontSizeIcon, calc } = token;
    const tagFontSize = token.fontSizeSM;
    const tagToken = mergeToken(token, {
        tagFontSize,
        tagLineHeight: unit(calc(token.lineHeightSM).mul(tagFontSize).equal()),
        tagIconSize: calc(fontSizeIcon).sub(calc(lineWidth).mul(2)).equal(), // Tag icon is much smaller
        tagPaddingHorizontal: 8, // Fixed padding.
        tagBorderlessBg: token.defaultBg,
    });
    return tagToken;
};
export const prepareComponentToken = (token) => ({
    defaultBg: new TinyColor(token.colorFillQuaternary)
        .onBackground(token.colorBgContainer)
        .toHexString(),
    defaultColor: token.colorText,
});
export default genStyleHooks('Tag', (token) => {
    const tagToken = prepareToken(token);
    return genBaseStyle(tagToken);
}, prepareComponentToken);
