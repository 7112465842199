import { useContext } from 'react';
import { genStyleUtils } from '@ant-design/cssinjs-utils';
import { ConfigContext } from '../../config-provider/context';
import { genCommonStyle, genLinkStyle } from '../../style';
import useLocalToken, { unitless } from '../useToken';
import useResetIconStyle from './useResetIconStyle';
export const { genStyleHooks, genComponentStyleHook, genSubStyleComponent } = genStyleUtils({
    usePrefix: () => {
        const { getPrefixCls, iconPrefixCls } = useContext(ConfigContext);
        const rootPrefixCls = getPrefixCls();
        return {
            rootPrefixCls,
            iconPrefixCls,
        };
    },
    useToken: () => {
        const [theme, realToken, hashId, token, cssVar] = useLocalToken();
        return { theme, realToken, hashId, token, cssVar };
    },
    useCSP: () => {
        const { csp, iconPrefixCls } = useContext(ConfigContext);
        // Generate style for icons
        useResetIconStyle(iconPrefixCls, csp);
        return csp !== null && csp !== void 0 ? csp : {};
    },
    getResetStyles: (token) => [{ '&': genLinkStyle(token) }],
    getCommonStyle: genCommonStyle,
    getCompUnitless: (() => unitless),
});
