var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import { Divider } from 'rc-menu';
import { ConfigContext } from '../config-provider';
const MenuDivider = (props) => {
    const { prefixCls: customizePrefixCls, className, dashed } = props, restProps = __rest(props, ["prefixCls", "className", "dashed"]);
    const { getPrefixCls } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('menu', customizePrefixCls);
    const classString = classNames({
        [`${prefixCls}-item-divider-dashed`]: !!dashed,
    }, className);
    return React.createElement(Divider, Object.assign({ className: classString }, restProps));
};
export default MenuDivider;
