import { genStyleHooks, mergeToken } from '../../theme/internal';
import genSpaceCompactStyle from './compact';
const genSpaceStyle = (token) => {
    const { componentCls, antCls } = token;
    return {
        [componentCls]: {
            display: 'inline-flex',
            '&-rtl': {
                direction: 'rtl',
            },
            '&-vertical': {
                flexDirection: 'column',
            },
            '&-align': {
                flexDirection: 'column',
                '&-center': {
                    alignItems: 'center',
                },
                '&-start': {
                    alignItems: 'flex-start',
                },
                '&-end': {
                    alignItems: 'flex-end',
                },
                '&-baseline': {
                    alignItems: 'baseline',
                },
            },
            [`${componentCls}-item:empty`]: {
                display: 'none',
            },
            // https://github.com/ant-design/ant-design/issues/47875
            [`${componentCls}-item > ${antCls}-badge-not-a-wrapper:only-child`]: {
                display: 'block',
            },
        },
    };
};
const genSpaceGapStyle = (token) => {
    const { componentCls } = token;
    return {
        [componentCls]: {
            '&-gap-row-small': {
                rowGap: token.spaceGapSmallSize,
            },
            '&-gap-row-middle': {
                rowGap: token.spaceGapMiddleSize,
            },
            '&-gap-row-large': {
                rowGap: token.spaceGapLargeSize,
            },
            '&-gap-col-small': {
                columnGap: token.spaceGapSmallSize,
            },
            '&-gap-col-middle': {
                columnGap: token.spaceGapMiddleSize,
            },
            '&-gap-col-large': {
                columnGap: token.spaceGapLargeSize,
            },
        },
    };
};
// ============================== Export ==============================
export const prepareComponentToken = () => ({});
export default genStyleHooks('Space', (token) => {
    const spaceToken = mergeToken(token, {
        spaceGapSmallSize: token.paddingXS,
        spaceGapMiddleSize: token.padding,
        spaceGapLargeSize: token.paddingLG,
    });
    return [
        genSpaceStyle(spaceToken),
        genSpaceGapStyle(spaceToken),
        genSpaceCompactStyle(spaceToken),
    ];
}, () => ({}), {
    // Space component don't apply extra font style
    // https://github.com/ant-design/ant-design/issues/40315
    resetStyle: false,
});
