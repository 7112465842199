var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import { get, set } from 'rc-util';
import useLayoutEffect from 'rc-util/lib/hooks/useLayoutEffect';
import Col from '../grid/col';
import { FormContext, FormItemPrefixContext } from './context';
import ErrorList from './ErrorList';
import FallbackCmp from './style/fallbackCmp';
const GRID_MAX = 24;
const FormItemInput = (props) => {
    const { prefixCls, status, labelCol, wrapperCol, children, errors, warnings, _internalItemRender: formItemRender, extra, help, fieldId, marginBottom, onErrorVisibleChanged, label, } = props;
    const baseClassName = `${prefixCls}-item`;
    const formContext = React.useContext(FormContext);
    const mergedWrapperCol = React.useMemo(() => {
        let mergedWrapper = Object.assign({}, (wrapperCol || formContext.wrapperCol || {}));
        if (label === null && !labelCol && !wrapperCol && formContext.labelCol) {
            const list = [undefined, 'xs', 'sm', 'md', 'lg', 'xl', 'xxl'];
            list.forEach((size) => {
                const _size = size ? [size] : [];
                const formLabel = get(formContext.labelCol, _size);
                const formLabelObj = typeof formLabel === 'object' ? formLabel : {};
                const wrapper = get(mergedWrapper, _size);
                const wrapperObj = typeof wrapper === 'object' ? wrapper : {};
                if ('span' in formLabelObj && !('offset' in wrapperObj) && formLabelObj.span < GRID_MAX) {
                    mergedWrapper = set(mergedWrapper, [..._size, 'offset'], formLabelObj.span);
                }
            });
        }
        return mergedWrapper;
    }, [wrapperCol, formContext]);
    const className = classNames(`${baseClassName}-control`, mergedWrapperCol.className);
    // Pass to sub FormItem should not with col info
    const subFormContext = React.useMemo(() => {
        const { labelCol, wrapperCol } = formContext, rest = __rest(formContext, ["labelCol", "wrapperCol"]);
        return rest;
    }, [formContext]);
    const extraRef = React.useRef(null);
    const [extraHeight, setExtraHeight] = React.useState(0);
    useLayoutEffect(() => {
        if (extra && extraRef.current) {
            setExtraHeight(extraRef.current.clientHeight);
        }
        else {
            setExtraHeight(0);
        }
    }, [extra]);
    const inputDom = (React.createElement("div", { className: `${baseClassName}-control-input` },
        React.createElement("div", { className: `${baseClassName}-control-input-content` }, children)));
    const formItemContext = React.useMemo(() => ({ prefixCls, status }), [prefixCls, status]);
    const errorListDom = marginBottom !== null || errors.length || warnings.length ? (React.createElement(FormItemPrefixContext.Provider, { value: formItemContext },
        React.createElement(ErrorList, { fieldId: fieldId, errors: errors, warnings: warnings, help: help, helpStatus: status, className: `${baseClassName}-explain-connected`, onVisibleChanged: onErrorVisibleChanged }))) : null;
    const extraProps = {};
    if (fieldId) {
        extraProps.id = `${fieldId}_extra`;
    }
    // If extra = 0, && will goes wrong
    // 0&&error -> 0
    const extraDom = extra ? (React.createElement("div", Object.assign({}, extraProps, { className: `${baseClassName}-extra`, ref: extraRef }), extra)) : null;
    const additionalDom = errorListDom || extraDom ? (React.createElement("div", { className: `${baseClassName}-additional`, style: marginBottom ? { minHeight: marginBottom + extraHeight } : {} },
        errorListDom,
        extraDom)) : null;
    const dom = formItemRender && formItemRender.mark === 'pro_table_render' && formItemRender.render ? (formItemRender.render(props, { input: inputDom, errorList: errorListDom, extra: extraDom })) : (React.createElement(React.Fragment, null,
        inputDom,
        additionalDom));
    return (React.createElement(FormContext.Provider, { value: subFormContext },
        React.createElement(Col, Object.assign({}, mergedWrapperCol, { className: className }), dom),
        React.createElement(FallbackCmp, { prefixCls: prefixCls })));
};
export default FormItemInput;
