export const prepareComponentToken = (token) => {
    const { fontSize, lineHeight, lineWidth, controlHeight, controlHeightSM, controlHeightLG, paddingXXS, controlPaddingHorizontal, zIndexPopupBase, colorText, fontWeightStrong, controlItemBgActive, controlItemBgHover, colorBgContainer, colorFillSecondary, colorBgContainerDisabled, colorTextDisabled, colorPrimaryHover, colorPrimary, controlOutline, } = token;
    // Item height default use `controlHeight - 2 * paddingXXS`,
    // but some case `paddingXXS=0`.
    // Let's fallback it.
    const dblPaddingXXS = paddingXXS * 2;
    const dblLineWidth = lineWidth * 2;
    const multipleItemHeight = Math.min(controlHeight - dblPaddingXXS, controlHeight - dblLineWidth);
    const multipleItemHeightSM = Math.min(controlHeightSM - dblPaddingXXS, controlHeightSM - dblLineWidth);
    const multipleItemHeightLG = Math.min(controlHeightLG - dblPaddingXXS, controlHeightLG - dblLineWidth);
    // FIXED_ITEM_MARGIN is a hardcode calculation since calc not support rounding
    const INTERNAL_FIXED_ITEM_MARGIN = Math.floor(paddingXXS / 2);
    return {
        INTERNAL_FIXED_ITEM_MARGIN,
        zIndexPopup: zIndexPopupBase + 50,
        optionSelectedColor: colorText,
        optionSelectedFontWeight: fontWeightStrong,
        optionSelectedBg: controlItemBgActive,
        optionActiveBg: controlItemBgHover,
        optionPadding: `${(controlHeight - fontSize * lineHeight) / 2}px ${controlPaddingHorizontal}px`,
        optionFontSize: fontSize,
        optionLineHeight: lineHeight,
        optionHeight: controlHeight,
        selectorBg: colorBgContainer,
        clearBg: colorBgContainer,
        singleItemHeightLG: controlHeightLG,
        multipleItemBg: colorFillSecondary,
        multipleItemBorderColor: 'transparent',
        multipleItemHeight,
        multipleItemHeightSM,
        multipleItemHeightLG,
        multipleSelectorBgDisabled: colorBgContainerDisabled,
        multipleItemColorDisabled: colorTextDisabled,
        multipleItemBorderColorDisabled: 'transparent',
        showArrowPaddingInlineEnd: Math.ceil(token.fontSize * 1.25),
        hoverBorderColor: colorPrimaryHover,
        activeBorderColor: colorPrimary,
        activeOutlineColor: controlOutline,
        selectAffixPadding: paddingXXS,
    };
};
