export function isValidWaveColor(color) {
    return (color &&
        color !== '#fff' &&
        color !== '#ffffff' &&
        color !== 'rgb(255, 255, 255)' &&
        color !== 'rgba(255, 255, 255, 1)' &&
        !/rgba\((?:\d*, ){3}0\)/.test(color) && // any transparent rgba color
        color !== 'transparent');
}
export function getTargetWaveColor(node) {
    const { borderTopColor, borderColor, backgroundColor } = getComputedStyle(node);
    if (isValidWaveColor(borderTopColor)) {
        return borderTopColor;
    }
    if (isValidWaveColor(borderColor)) {
        return borderColor;
    }
    if (isValidWaveColor(backgroundColor)) {
        return backgroundColor;
    }
    return null;
}
